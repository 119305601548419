<template>

    <router-link class="order-row flex justify-btw align-center" :to="{ name: 'orderPage', params: {
        id: order.orderID
    } }">
        <span class="order-id"> {{ order.orderID }} </span>
        <span class="brand"> {{ order.brand }} </span>
        <span class="model"> {{ order.model }} </span>
        <span class="issue"> {{ order.issue }} </span>
        <span class="c-name"> {{ order.customerName }} </span>
        <span class="status" :class="classObj">
            <i>
            {{ status }}
            </i>
        </span>
        <span class="order-date"> {{ orderDate }} </span>
        <span class="expected-delivery-date"> {{ expectedDeliveryDate }} </span>
    </router-link>

</template>

<script>
	export default {
        data() {
            return {
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            }
        },
		props: {
			order: {
				type: Object,
				required: true
			}
        },
        computed: {
            orderDate() {
                var date = new Date( this.order.orderDate );
                return this.months[date.getMonth()] + " " + date.getDate() ;
            },
            expectedDeliveryDate() {
                var date = new Date( this.order.expectedDeliveryDate );
                return this.months[date.getMonth()] + " " + date.getDate() ;
            },
            status() {
                return !this.order.fixed ? "active" : this.order.delivered ? "delivered" : "fixed";
            },
            classObj() {
                return {
                    "active": this.status === "active",
                    "fixed": this.status === "fixed",
                    "delivered": this.status === "delivered"
                }
            }
        },
	};
</script>

<style scoped>

.order-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

span
{
    width: calc(100% / 8);
    padding-right: 25px;
    font-weight: 600;
    font-size: 14.5px;
    line-height: 1.5;
    color: black;
}

.order-id, .brand, .model { width: 100px; }

.c-name, .status, .order-date { width: 120px; }

.issue { min-width: 250px; }

.expected-delivery-date { width: 165px; }

.order-id
{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.model, .c-name, .issue
{
    text-transform: capitalize;
}

.order-row:hover
{
    background-color: #000;
}

.order-row:hover span { color: #fff; }

.status { padding: 0; }

.status i
{
    padding: 10px 20px;
    display: inline-block;
    border-radius: 15px;
    color: whitesmoke;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1;
}

.status.fixed i { background-color: #00917c; }

.status.active i { background-color: #51c2d5; }

.status.delivered i { background-color: #ff9a76; }

</style>
