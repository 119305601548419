<template>
<div>

    <div class="orders-menu-row flex align-center">
        <div class="search-box">
            <input type="text"
                class="order-search" id="order-search"
                placeholder="Search.."
                @input="search" v-model="searchText" />
            <div
                class="icon-sprite search-icon"
                @click="search">
            </div>
        </div>

        <p
            class="search-msg"
            v-if=" searchText !== '' ">
            Showing {{ searchResult.length }} of {{ orders.length }} orders.
        </p>

        <div
            class="pagination flex justify-btw align-center"
            v-if=" 1 < noOfPages && searchText === '' " >

            <div class="icon-sprite prev-page-icon"
                :class="{ 'hide': pageNo === 1 }"
                @click="pageNo--" ></div>

            <p class="page-show">
                Page {{ pageNo }} of {{ noOfPages }}
            </p>

            <div class="icon-sprite next-page-icon"
                :class="{ 'hide': pageNo === noOfPages }"
                @click="pageNo++" ></div>
        </div>
    </div>


    <div class="order-table loader-table"
        v-if=" !$store.state.ordersStored "
    >
        <div class="order-header-row flex justify-btw align-center">
            <span class="order-id"> order ID </span>
            <span class="brand"> brand </span>
            <span class="model"> model </span>
            <span class="issue"> issue </span>
            <span class="c-name"> customer Name </span>
            <span class="status"> Status </span>
            <span class="order-date"> order Date </span>
            <span class="expected-delivery-date"> Expected Delivery Date </span>
        </div>

        <div class="order-row flex align-center justify-btw">
            <span class="order-id"> <i class="pseudo-loader"></i>  </span>
            <span class="brand"> <i class="pseudo-loader"></i>  </span>
            <span class="model"> <i class="pseudo-loader"></i>  </span>
            <span class="issue"> <i class="pseudo-loader"></i>  </span>
            <span class="c-name"> <i class="pseudo-loader"></i>  </span>
            <span class="status"> <i class="pseudo-loader"></i>  </span>
            <span class="order-date"> <i class="pseudo-loader"></i>  </span>
            <span class="expected-delivery-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="order-row flex align-center justify-btw">
            <span class="order-id"> <i class="pseudo-loader"></i>  </span>
            <span class="brand"> <i class="pseudo-loader"></i>  </span>
            <span class="model"> <i class="pseudo-loader"></i>  </span>
            <span class="issue"> <i class="pseudo-loader"></i>  </span>
            <span class="c-name"> <i class="pseudo-loader"></i>  </span>
            <span class="status"> <i class="pseudo-loader"></i>  </span>
            <span class="order-date"> <i class="pseudo-loader"></i>  </span>
            <span class="expected-delivery-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="order-row flex align-center justify-btw">
            <span class="order-id"> <i class="pseudo-loader"></i>  </span>
            <span class="brand"> <i class="pseudo-loader"></i>  </span>
            <span class="model"> <i class="pseudo-loader"></i>  </span>
            <span class="issue"> <i class="pseudo-loader"></i>  </span>
            <span class="c-name"> <i class="pseudo-loader"></i>  </span>
            <span class="status"> <i class="pseudo-loader"></i>  </span>
            <span class="order-date"> <i class="pseudo-loader"></i>  </span>
            <span class="expected-delivery-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="order-row flex align-center justify-btw">
            <span class="order-id"> <i class="pseudo-loader"></i>  </span>
            <span class="brand"> <i class="pseudo-loader"></i>  </span>
            <span class="model"> <i class="pseudo-loader"></i>  </span>
            <span class="issue"> <i class="pseudo-loader"></i>  </span>
            <span class="c-name"> <i class="pseudo-loader"></i>  </span>
            <span class="status"> <i class="pseudo-loader"></i>  </span>
            <span class="order-date"> <i class="pseudo-loader"></i>  </span>
            <span class="expected-delivery-date"> <i class="pseudo-loader"></i> </span>
        </div>
    </div>

    <div class="order-table"
        v-if=" searchText === '' && $store.state.ordersStored "
    >
        <div class="order-header-row flex justify-btw align-center">
            <span class="order-id"> order ID  <div class="sort-icon" data-name="orderID" @click="sort"></div> </span>
            <span class="brand"> brand  <div class="sort-icon" data-name="brand" @click="sort"></div> </span>
            <span class="model"> model  <div class="sort-icon" data-name="model" @click="sort"></div> </span>
            <span class="issue"> issue  <div class="sort-icon" data-name="issue" @click="sort"></div> </span>
            <span class="c-name"> customer Name  <div class="sort-icon" data-name="customerName" @click="sort"></div> </span>
            <span class="status"> Status </span>
            <span class="order-date"> order Date <div class="sort-icon" data-name="orderDate" @click="sort"></div> </span>
            <span class="expected-delivery-date"> Expected Delivery Date <div class="sort-icon" data-name="expectedDeliveryDate" @click="sort"></div></span>
        </div>

        <OrderRow v-for="order in pageOrders" :key="order.OrderID"  :order="order" />

        <p v-if="orders.length === 0" class="no-order flex center">
            There are no orders.
        </p>

    </div>

    <div class="order-table" v-if=" searchText !== '' && $store.state.ordersStored ">
        <div class="order-header-row flex justify-btw align-center">
            <span class="order-id"> order ID  <div class="sort-icon" data-name="orderID" @click="sort"></div> </span>
            <span class="brand"> brand  <div class="sort-icon" data-name="brand" @click="sort"></div> </span>
            <span class="model"> model  <div class="sort-icon" data-name="model" @click="sort"></div> </span>
            <span class="issue"> issue  <div class="sort-icon" data-name="issue" @click="sort"></div> </span>
            <span class="c-name"> customer Name  <div class="sort-icon" data-name="customerName" @click="sort"></div> </span>
            <span class="status"> Status </span>
            <span class="order-date"> order Date <div class="sort-icon" data-name="orderDate" @click="sort"></div> </span>
            <span class="expected-delivery-date"> Expected Delivery Date <div class="sort-icon" data-name="expectedDeliveryDate" @click="sort"></div></span>
        </div>
        <OrderRow v-for="order in searchResult" :key="order.OrderID"  :order="order"/>

        <p v-if="searchResult.length === 0" class="no-order flex center">
            There are no orders.
        </p>
    </div>

</div>
</template>

<script>
import OrderRow from '@/components/admin/Orders/OrderRow.vue';
import $ from 'jquery';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            searchResult: [],
            searchText: '',
            pageNo: 1
        }
    },
    components: {
        OrderRow,
    },
    props: {
        orders: {
            type: Array,
            default: null,
            required: true
        }
    },
    methods: {
        search() {
            if( this.searchText === '' )
            {
                this.searchResult = this.orders;
                return;
            }
            this.orderType = "";
            this.searchResult = this.orders.filter( order => {
                var values = Object.values( order );
                for( let i=0; i< values.length; i++ ) {
                    if( typeof values[i] === "string" && values[i].toLowerCase().indexOf( this.searchText.toLowerCase() ) !== -1 )
                        return true;
                }
            });
        },
        sort(e) {

            var { name } = e.target.dataset;
            var order = $(e.target).hasClass('asc-order');
            if( this.searchText === '' )
            {
                this.orders.sort( (a,b) =>
                {

                    if( !order )
                    {
                        if( a[name] < b[name] ) { return -1; }
                        if( a[name] > b[name] ) { return 1; }
                    } else {
                        if( a[name] < b[name] ) { return 1; }
                        if( a[name] > b[name] ) { return -1; }
                    }

                    return 0;
                });
            }
            else
            {
                this.searchResult.sort( (a,b) => {

                    if( !order ) {
                        if( a[name] < b[name] ) { return -1; }
                        if( a[name] > b[name] ) { return 1; }
                    } else {
                        if( a[name] < b[name] ) { return 1; }
                        if( a[name] > b[name] ) { return -1; }
                    }

                    return 0;
                });
            }
            $('.asc-order').removeClass('asc-order');
            if( order ) { $(e.target).removeClass('asc-order'); }
            else { $(e.target).addClass('asc-order'); }
        }
    },
    computed: {
        noOfPages() {
            return Math.ceil(this.orders.length / 20)
        },
        pageOrders() {
            var start = (this.pageNo - 1) * 20;
            return this.orders.slice(start, start + 20);
        },
        ...mapGetters(['allOrders'])
    },
    watch: {
        orders(newValue, oldValue)
        {
            if( newValue !== oldValue )
            {
                this.pageNo = 1;
                this.searchText = '';
            }
        }
    },
}
</script>

<style scoped>

.orders-menu-row, .orders-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
    background-color: #fff;
}

.search-msg
{
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    margin-left: 24px;
    padding-top: 5px;
}

.search-box
{
    position: relative;
}

.order-search
{
    height: 42px;
    width: 300px;
    padding-left: 15px;
    padding-right: 25px;
    border-radius: 15px;
    outline: none;
    border: 3px solid #323232;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
}

.order-search:focus { border: 3px solid#00917c; box-shadow: 0; }

.search-icon
{
    background-size: 1272.9px 888.9px;
    background-position: -411.6px -603.6px;
	height: 16px; width: 16px;
    cursor: pointer;
    position: absolute;
    top: 50%; right: 18px;
    transform: translateY(-50%);
}

.order-table
{
    margin: 30px auto;
    border: 3px solid #000;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: auto;
    width: calc(100% - 50px);
    background-color: #fff;
}

.order-header-row
{
    height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    position: -webkit-sticky;
    position: sticky;
    top: 0; left: 0;
    background-color: grey;
    z-index: 2;
    min-width: 1370px;
}

span
{
    width: calc(100% / 8);
    font-weight: 600;
    font-size: 12.5px;
    line-height: 20px;
    padding-right: 25px;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.sort-icon
{
    position: absolute;
    top: 50%; right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid white;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    display: none;
}

.order-header-row span:hover .sort-icon
{
    display: block;
}

.sort-icon.asc-order
{
    transform: translateY(-50%) rotate(180deg);
}

.order-id, .brand, .model { width: 100px; }

.c-name, .status, .order-date { width: 120px; }

.expected-delivery-date { width: 165px; }

.issue { width: 250px; }

.page-show
{
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    margin: 0 30px;
}

.pagination
{
    margin-left: auto;
}

.icon-sprite.prev-page-icon, .icon-sprite.next-page-icon
{
    background-size: 421.2px 294.1px;
    background-position: -382.4px -32px;
	height: 30px; width: 30px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgb(0,0,0,0.1));
    transition: transform 0.2s ease;
}

.icon-sprite.next-page-icon{ transform: rotate(180deg); }

.prev-page-icon:hover { transform: scale3d(1.1,1.1,1.1); }

.next-page-icon:hover { transform: rotate(180deg) scale3d(1.1,1.1,1.1); }

.hide
{
    visibility: hidden;
}

p.no-order
{
    font-size: 35px;
    height: calc(100% - 40px);
}

/* Loader */

.order-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

.order-row span i { height: 20px; width: 75% !important; }

</style>