<template>
	<div>
		<SideBar />
        <OrderComponent />
	</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import OrderComponent from '@/components/admin/Orders/OrderComponent.vue';
import { Orders } from '../../services/Service';

export default {
    components: {
        SideBar,
        OrderComponent
    },
    methods: {
        async fetchAllOrders() {
            const response = await Orders.get({ type: 'all' });
            this.$store.dispatch('storeOrders', response.data.result);
        }
    },
    created() {
        this.fetchAllOrders();
    }
};
</script>

<style scoped>

</style>
