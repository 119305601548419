<template>
<div class="order-container">

    <div class="orders-top-pane flex align-center">
        <h2> Orders </h2>

        <div class="select-box">
            <select id="order-select" class="order-select" v-model="orderType">
                <option value="all orders" selected>All Orders</option>
                <option value="active">Active</option>
                <option value="fixed">Fixed</option>
                <option value="delivered">Delivered</option>
            </select>
            <div class="icon-sprite order-select-icon"></div>
        </div>

        <button class="btn add-new-order-btn fancy-btn" @click="openAddOrderOverlay">
            Add New Order
        </button>
    </div>

    <OrderTable
        v-if="orderType === 'all orders'"
        :orders="allOrders" />
    <OrderTable
        v-if="orderType === 'active'"
        :orders="activeOrders" />
    <OrderTable
        v-if="orderType === 'fixed'"
        :orders="fixedOrders" />
    <OrderTable
        v-if="orderType === 'delivered'"
        :orders="deliveredOrders" />

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal add-order-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay" class="close-modal-icon"></span>
            <h2>Add New Order</h2>

            <form class="flex">

                <div class="form-row flex">
                    <div class="form-element">
                        <input
                            type="text" data-name="brand" data-len="3"
                            id="brand" class="form-input"
                            placeholder="Mobile brand *" v-model="brand"
                            @input="handleInput" @blur="validateFields">
                    </div>
                    <div class="form-element c-email-outer">
                        <input
                            type="text" data-name="model" data-len="1"
                            id="model" class="form-input"
                            placeholder="Mobile model *" v-model="model"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>

                <div class="form-row flex">
                    <div class="form-element issue-fe">
                        <textarea
                            data-name="issue" data-len="10"
                            id="issue" class="form-input issue"
                            placeholder="Issue in mobile *" v-model="issue"
                            @input="handleInput" @blur="validateFields">
                        </textarea>
                    </div>
                </div>

                <div class="form-row flex">
                    <div class="form-element">
                        <input
                            type="number" data-name="estimated price" data-len="1"
                            id="estimated-price" class="form-input estimated-price"
                            placeholder="Estimated price in Rs *" v-model="priceEstimate"
                            @input="handleInput" @blur="validateFields">
                    </div>
                    <div class="form-element flex">
                        <label class="battery-text"> With Sim: </label>
                        <label class="switch">
                            <input type="checkbox" v-model="withSim">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>


                <div class="form-row flex">
                    <div class="date-outer form-element">
                        <input id="proxy-date" style="display: none;" data-name="date" type="hidden" />
                        <Datepicker
                            class="date-picker" placeholder="Expected delivery date"
                            v-model="expectedDeliveryDate" wrapper-class="exp-date-wrapper"
                            input-class="exp-date-input" calendar-class="exp-date-cal"
                            :monday-first="true" :disabled-dates="disabledDates"
                        >
                        </Datepicker>
                        <div class="icon-sprite dropdown"></div>
                    </div>
                    <div class="time-outer form-element">
                        <select
                            id="app-time"
                            class="form-input"
                            v-model="expectedDeliveryTime"
                            data-name="time"
                        >
                            <option v-for="(t,i) in timeStramp" :key="i" :value="t.time"
                                :disabled="t.disabled === true">
                                    {{ t.time }}
                            </option>
                        </select>
                        <label for="app-time" class="app-time-label">Expected delivery time</label>
                        <div class="icon-sprite dropdown"></div>
                    </div>
                </div>

                <div class="form-row flex">
                    <div class="form-element">
                        <input
                            type="text" data-name="customer name" data-len="4"
                            id="customer-name" class="form-input"
                            placeholder="Customer name*" v-model="customerName"
                            @input="handleInput" @blur="validateFields">
                    </div>
                    <div class="form-element c-email-outer">
                        <input
                            type="email" data-name="customer email"
                            id="c-email" class="form-input c-email"
                            placeholder="Customer Email" v-model="customerEmail"
                            @input="handleInput" @blur="validateEmail">
                    </div>
                </div>

                <div class="form-row flex">
                    <div class="form-element">
                        <input
                            type="number" data-name="Mobile Number" data-len="10"
                            id="c-mobile-number" class="form-input"
                            placeholder="Mobile Number" v-model="mobileNumber"
                            @input="handleInput" @blur="validateFields">
                    </div>
                </div>

                <div class="btns-box flex">
                    <button class="btn fancy-btn confirm-btn order-save-btn" type="button" @click="addOrder">ADD</button>
                    <button class="btn fancy-btn cancel-btn" @click="closeOverlay" type="button">CANCEL</button>
                </div>
            </form>
        </div>
    </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderTable from '@/components/admin/Orders/OrderTable.vue';
import Datepicker from 'vuejs-datepicker';
import {Orders} from '@/services/Service.js'

import $ from 'jquery';

const timeStramp = [{time: '9 AM'},{time: '10 AM'},{time: '11 AM'},{time: '12 PM'}];
for(let i = 1; i<9; i++ ) timeStramp.push( { time: i + ' PM' } );

export default {
    data() {
        return {
            orderType: "all orders",
            brand: '',
            model: '',
            issue: '',
            priceEstimate: '',
            expectedDeliveryDate: '',
            expectedDeliveryTime: '',
            customerName: '',
            customerEmail: '',
            mobileNumber: '',
            withSim: true,
            timeStramp,
            disabledDates: {
                to: new Date(),
                days: [0]
            }
        }
    },
    components: {
        OrderTable,
        Datepicker
    },
    computed: {
        ...mapGetters([
            'allOrders',
            'activeOrders',
            'fixedOrders',
            'deliveredOrders'
        ])
    },
    methods: {
        openAddOrderOverlay() {
            $('.overlay').removeClass('d-none');
            $('.add-order-modal').removeClass('d-none');
        },
        handleModalClick(e) {
            e.stopPropagation();
        },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
        },
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} char.`);
        },
        validateEmail({target: el})
        {
            if(el.value === '')
                return this.addErrorMessage( el.id );

            var atposition = el.value.indexOf("@");
            var dotposition = el.value.lastIndexOf(".");

            if( atposition<1 || dotposition<atposition+2 || dotposition+2>=el.value.length)
                this.addErrorMessage( el.id, `Invalid Email.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        async addOrder() {
            if( this.brand === '' )
                this.addErrorMessage('brand');
            else if( this.brand.length < 3 )
                this.addErrorMessage('brand', 'Brand must have atleast 3 chars.' );

            if( this.model === '' )
                this.addErrorMessage('model');

            if( this.priceEstimate === '' )
                this.addErrorMessage('estimated-price');

            if( this.issue === '' )
                this.addErrorMessage('issue');
            else if( this.issue.length < 10 )
                this.addErrorMessage('issue','issue must have atleast 10 chars.');

            if( this.customerName === '' )
                this.addErrorMessage('customer-name');
            else if( this.customerName.length < 4 )
                this.addErrorMessage('customer-name', 'customer Name must have atleast 4 chars.' );

            if( $('.add-order-modal .error-msg').length ) return;
            $('.order-save-btn').blur();
            $('.order-save-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Orders.add({
                    brand: this.brand,
                    model: this.model,
                    issue: this.issue,
                    priceEstimate: this.priceEstimate,
                    expectedDeliveryDate: this.expectedDeliveryDate === '' ? undefined : this.expectedDeliveryDate,
                    expectedDeliveryTime: this.expectedDeliveryTime === '' ? undefined : this.expectedDeliveryTime,
                    customerName: this.customerName,
                    customerEmail: this.customerEmail === '' ? undefined : this.customerEmail,
                    mobileNumber: this.mobileNumber === '' ? undefined : this.mobileNumber,
                    withSim: this.withSim,
                });
                if( response.status === 200 )
                {
                    $('.order-save-btn').html(`Add`);
                    const notification = {
                        type: 'success',
                        message: 'Order added successfully.'
                    };
                    this.$store.dispatch('addNotification', notification);
                    this.allOrders.unshift( response.data.order );

                    this.brand = '';
                    this.model = '';
                    this.issue = '';
                    this.priceEstimate = '';
                    this.expectedDeliveryDate = '';
                    this.expectedDeliveryTime = '';
                    this.customerName = '';
                    this.customerEmail = '';
                    this.mobileNumber = '';
                    this.withSim = false;

                    this.closeOverlay();
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message }
                $('.order-save-btn').html(`Add`);

                this.$store.dispatch('addNotification', notification);
            }
        }
    },
    watch: {
        expectedDeliveryTime(newValue) {

            if( newValue === '' ) return;
            $('.app-time-label').addClass('d-none');
        },
    },
}
</script>

<style scoped>

.order-container
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
}

.orders-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
    z-index: 2;
}

h2
{
    font-weight: 800;
}

.select-box
{
    margin-left: 50px;
    height: 42px;
    width: 150px;
    position: relative;
    cursor: pointer;
}

.order-select
{
    height: 100%; width: 100%;
    padding: 3px 12px;
    border-radius: 12px;
    outline: none;
    border: 3px solid #323232;
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.order-select-icon
{
    background-size: 100.7px 70.3px;
    background-position: -64.7px -2.8px;
	width: 18px; height: 9px;
    pointer-events: none;
    position: absolute;
    top: 50%; right: 18px;
    transform: translateY(-50%);
}

.add-new-order-btn
{
    margin-left: auto;
    height: 45px; width: 200px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    background-color: #00917c;
    color: #fff;
    font-weight: 700;
}

/* Overlay */

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 350px;
    border-radius: 12px;
    padding: 60px 30px 30px;
}

.add-order-modal
{
    padding: 40px 30px 20px;
    width: 100%;
    max-width: 700px;
    overflow-y: auto;
    height: calc(100vh - 100px);
    background-image: url('../../../assets/Goemetirc Textures.png');
}

.close-modal-icon
{
    position: fixed;
    right: 15px; top: 15px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.close-modal-icon::before, .close-modal-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.close-modal-icon::before { transform: translateX(-50%) rotate(45deg); }

.close-modal-icon::after { transform: translateX(-50%) rotate(-45deg); }

.close-modal-icon:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.btns-box { margin-top: 25px; }

.confirm-btn, .cancel-btn
{
    height: 45px; width: 200px;
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-left: auto; margin-right: 15px; }

.cancel-btn { background-color: #E20613; margin-right: auto; }

.modal h2
{
    margin-bottom: 50px;
    text-align: center;
    font-size: 30px;
}

form { flex-direction: column; }

form>* { margin-bottom: 24px; }

.form-row
{
    justify-content: space-between;
}

.form-input
{
    height: 45px; width: 100%;
    border: 2px solid #000;
    background-color: white;
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 3px;
}

.form-input:focus { border-color: #00917c; }

.form-element
{
    position: relative;
    width: calc(50% - 10px);
}

.issue-fe { width: 100%; }

.issue { height: 80px; padding: 15px; resize: none; }

.app-time-label
{
    position: absolute;
    left: 18px;
    top: 15px;
    pointer-events: none;
    color: grey;
}

.battery-text { font-size: 15px; margin-right: 12px; padding: 16px 0 0; line-height: 1; }

.switch
{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    margin-top: 10px;
}

.switch input
{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider
{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before
{
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 1.2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider { background-color: #00917c; }

input:focus + .slider { box-shadow: 0 0 1px #00917c; }

input:checked + .slider:before
{
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round { border-radius: 34px; }

.slider.round:before { border-radius: 50%; }

.dropdown
{
    background-size: 100.7px 70.3px;
    background-position: -64.7px -2.8px;
	width: 18px; height: 9px;
    position: absolute;
    right: 15px; top: 45%;
    transform: translateY(-50%);
    pointer-events: none;
}

</style>


<style>

.exp-date-input
{
    cursor: context-menu;
    outline: 0;
    background-color: #fff;
    color: black;
    border: 2px solid #000;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    height: 45px; width: 310px;
    border-radius: 3px;
}

.exp-date-cal { background-color: white; color: black; }

.exp-date-wrapper { color: white; }

@media (max-width: 475px)
{
    .exp-date-input { width: 100% !important; }
}

</style>